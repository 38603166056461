// redux/slices/curationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postRequestAuthenticated } from "./../../services/rest";

export const fetchTopics = createAsyncThunk(
  "reorderTopics/fetchChannelTopics",
  async (channelId, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticated("/fetch/channel/topics", {
        channelId,
      });
      if (response.success) {
        return response.topics;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTopicsOrder = createAsyncThunk(
  "reorderTopics/updateTopicsOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticated(
        "/update/channel/topics/order",
        data
      );
      const topicData = {
        topics: response.topics,
        channelId: response.channelId,
      };
      if (response.success) {
        return topicData;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  topics: [],
  reorderTopics: [],
  status: "idle",
  error: false,
};

export const reorderTopicSlice = createSlice({
  name: "reorderTopic",
  initialState,
  reducers: {
    setReorderTopicField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    clearItems: (state) => {
      state.topics = [];
      state.status = "idle";
      state.error = null;
    },
    updateReorderTopics: (state, action) => {
      const { itemId, newCategoryId, type } = action.payload;
      const itemExists = state.reorderItems.find(
        (item) => item.itemId === itemId
      );

      if (itemExists) {
        state.reorderItems = state.reorderItems.map((item) =>
          item.itemId === itemId ? { ...item, newCategoryId, type } : item
        );
      } else {
        state.reorderItems.push({ itemId, newCategoryId, type });
      }
    },
    clearReorderTopics: (state) => {
      state.reorderTopics = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopics.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        state.status = "idle";
        state.topics = action.payload;
      })
      .addCase(updateTopicsOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTopicsOrder.fulfilled, (state, action) => {
        state.status = "idle";
        const topicData = action.payload;
        const topics = topicData.topics;
        state.topics = topics;
      });
  },
});

export const {
  updateReorderTopics,
  clearItems,
  setReorderTopicField,
  clearReorderTopics,
} = reorderTopicSlice.actions;

export default reorderTopicSlice.reducer;
